import React, { useContext } from 'react';

import { Link } from '@reach/router';
import cx from 'classnames'
import LogoutButton from '../LogoutButton';
import logo from '../../images/boomer.svg'
import styles from './Nav.module.scss';
import { AuthContext } from '../../services/auth/AuthProvider';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import { APP_NAME } from '../../services/constants';
import ls from 'local-storage';

const Nav = () => {

    const { currentUser } = useContext(AuthContext);
    const token = ls(getAppTokenName(APP_NAME));
    return (
        <div className={styles.menu}>
            <div className={styles.menuContent}>
                <div className={styles.menuBrand}><Link to="/"><img src={logo} alt="BoomWriter" /></Link></div>
                <ul className={styles.menuNav}>
                    <li><Link to='/' className={cx(styles.menuNavItem, styles.menuNavItemHome)}>Home</Link></li>

                    {currentUser.school?.canAccessBoomWriteApp && (
                        <li><a href={`${getUrl(APPS.go_write_pupil)}?token=${token}`} className={cx(styles.menuNavItem, styles.menuNavItemWrite)}>Write</a></li>
                    )}
                    {currentUser.school?.canAccessGoReadApp && (
                        <li><a href={`${getUrl(APPS.go_read_pupil)}?token=${token}`} className={cx(styles.menuNavItem, styles.menuNavItemRead)}>Read</a></li>
                    )}

                    <li><Link to="/rewards" className={cx(styles.menuNavItem, styles.menuNavItemRewards)}>Rewards</Link></li>
                    {currentUser.boomerAvatarUrl ?
                        <li><Link to="/boomer" className={cx(styles.menuNavItem, styles.menuNavItemBoomer)} style={{ backgroundImage: `url(${currentUser.boomerAvatarUrl}?v=${currentUser.updatedAt.replace(/\s/g, '')}` }}>Boomer</Link></li> :
                        <li><Link to="/boomer" className={cx(styles.menuNavItem, styles.menuNavItemBoomer)}>Boomer</Link></li>
                    }
                </ul>
                <div className={styles.menuNavExit}>
                    <LogoutButton classes={cx(styles.menuNavItem, styles.menuNavExitBtn)}>
                        Logout
                    </LogoutButton>
                </div>
            </div>
        </div>
    );
}

export default Nav;
