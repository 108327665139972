import React, { useContext } from 'react';
import { Link } from '@reach/router';
import ls from 'local-storage';
import cx from 'classnames';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import { AuthContext } from '../../services/auth/AuthProvider';

import { APP_NAME } from '../../services/constants';

import styles from './Dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import write from '../../images/write_circle.png'
import loader from '../../images/loader-boomer.png'
import read from '../../images/read_circle.png'
import rewards from '../../images/rewards_circle.png'
import gem from './images/gem.svg';

const Dashboard = () => {

    const { t } = useTranslation();
    const token = ls(getAppTokenName(APP_NAME));

    const { currentUser } = useContext(AuthContext);

    return (
        <div className={styles.dashboard}>
            <div className={styles.welcome}>
                <div className={styles.welcomeInner}>
                    <div className={styles.welcomeBoomerHolder}>
                        <img src={currentUser.boomerAvatarUrl ? currentUser.boomerAvatarUrl : loader} className={styles.welcomeBoomer} />
                    </div>
                    <h1 className='u-bold u-bottom-tight'>{currentUser.firstName} {currentUser.lastName}</h1>
                    <div className={styles.gemPanel}>
                        <img src={gem} className={styles.gem} alt={t('gem')} />
                        <p>{currentUser.availableGems} {currentUser.availableGems === 1 ? t('gem') : t('gems')}</p>
                    </div>
                </div>
            </div>
            <div className={styles.appLinks}>

                <a className={cx(styles.appLink, { [styles.appLinkDisabled]: !currentUser.school.canAccessBoomWriteApp }) } href={`${getUrl(APPS.go_write_pupil)}?token=${token}`}>
                    <img src={write} className={styles.iconImg} />
                    <span>Write</span>
                </a>
                <a className={cx(styles.appLink, { [styles.appLinkDisabled]: !currentUser.school.canAccessGoReadApp })} href={`${getUrl(APPS.go_read_pupil)}?token=${token}`}>
                    <img src={read} className={styles.iconImg} />
                    <span>Read</span>
                </a>

                <Link to='/rewards' className={styles.appLink}>
                    <img src={rewards} className={styles.iconImg} />
                    <span>Rewards</span>
                </Link>
                <Link to='/boomer' className={styles.appLink}>
                    <div className={styles.iconHolder}>
                        <img src={currentUser.boomerAvatarUrl ? `${currentUser.boomerAvatarUrl}?v=${currentUser.updatedAt.replace(/\s/g, '')}` : loader} className={styles.iconImg} />
                    </div>
                    <span>Boomer</span>
                </Link>
            </div>
        </div>
    )
}

export default Dashboard;
